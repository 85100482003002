/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  Matching,
} from '../interfaces/ProductService.interface';
import { ShopwareHelper } from '../tools/ShopwareHelper';
import { PodolinoHelper } from './PodolinoHelper';
import AbstractShop from './AbstractShop';
import axios from 'axios';
import log from '../services/LogService';

const stagingKey = '25f80fc9-8379-5090-b8c6-7de96d672316';

function isStaging() {
  const url = new URL(window.location.href);
  return url.hostname.includes('staging');
}

export class PodolinoService extends AbstractShop {
  articleId: string;
  shopware: ShopwareHelper;
  helper: PodolinoHelper;

  constructor() {
    super();
    this.apiKey = isStaging() ? stagingKey : process.env.PODOLINO_API_KEY;
    this.themeName = process.env.PODOLINO_THEME;
    const article = window.lastSeenProductsConfig.currentArticle;
    this.helper = new PodolinoHelper();
    this.articleNumber = this.helper.parseArticleNumber(article);
    this.articleName = article.articleName;
    const artNr: HTMLElement = document.querySelector('[itemprop=sku]');
    this.customGv = this.helper.getCustomGV(artNr, article);
    this.sizes = this.helper.getArticleSizesFromUI();
    this.articleId = article.articleId;
    this.clientSessionId = this.readClientCookie('session-1');
    const priceEl = document.querySelector(
      'meta[itemprop=price]',
    ) as HTMLElement;

    if (priceEl) {
      const priceValue = priceEl.getAttribute('content');
      this.price = Number(priceValue);
    }

    this.productSrc = article.images[0].source;

    this.shopware = new ShopwareHelper(
      process.env.PODOLINO_SHOPWARE_DOMAIN,
      process.env.PODOLINO_SHOPWARE_USER,
      process.env.PODOLINO_SHOPWARE_KEY,
      process.env.PODOLINO_SHOESIZE_GROUP,
    );

    const sessionCookie = document.cookie
      .split(';')
      .find((item) => item.includes('session-1'));
    log.info('session cookie', sessionCookie);
  }

  /* Interface implentation */

  getTarget() {
    log.info('Podolino get target called');

    if (this.articleName.toLowerCase().includes('staging')) {
      return 'https://staging-app.myfootprint.app';
    }

    return process.env.FPT_APP_URL;
  }

  handleMatching(matching: Matching) {
    log.info('handling matching');
    const url = new URL(window.location.href);
    const isPodolinoStaging = url.hostname === 'staging.podolino.de';

    if (isPodolinoStaging) {
      log.info('skipping add to cart');
      return super.handleMatching(matching);
    }

    log.info('adding to cart');
    return this.shopware.getArticleById(this.articleId)
      .then(articleData => {
        this.shopware.addToCart(articleData, matching.size.value);
      }).catch(error => {
        console.error('Unable to get article data', error);
      });

  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': process.env.PODOLINO_API_KEY,
        'X-SHOP-DOMAIN': window.location.origin,
      },
    });
  }

  hasTooltip() {
    return true;
  }
}
