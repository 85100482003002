import {
  PodolinoService,
  TestModeService,
  KoyobearService,
  SpartacusService,
  swtestenvironmentService,
  PodolinoLiveService,
  LiveServiceForEltenTesting,
  LiveServiceForTryon,
  MyfootprintBigCartelService,
  MiretService,
  BillyFootwearService,
  VicinityService,
  GenericService,
} from '../services';
import log from '../services/LogService';

export const ServiceMap = {
  'www.koyobear.com': KoyobearService,
  'koyo-bear-test-4-2.webflow.io': KoyobearService,
  'koyo-bear-footprint-test.webflow.io': KoyobearService,
  'www.vicinityclo.de': VicinityService,
  'billyfootwear.com': BillyFootwearService,
  'sandbox-billy-footwear.myshopify.com': BillyFootwearService,
  'www.miret.co': MiretService,
  'www.earthbound-sneakers.com': MiretService,
  'jp.koyobear.com': KoyobearService,
  'elten.footprinttech.de': LiveServiceForEltenTesting,  // The test shop for Elten's PCH Testing on 28.03.2023
  'fashion-spa.cu2qdtboy0-public1-p13-public.model-t.cc.commerce.ondemand.com': SpartacusService,
  'fashion-spa.sfashion-sapphire.usdemo.hybris.com': SpartacusService,
  'footprintapp-egsvi.sw-testenvironment.de': swtestenvironmentService,

  'shopware.podolino.de': PodolinoService,
  'staging.podolino.de': PodolinoService,
  'orange-ocean-017423203.1.azurestaticapps.net': TestModeService,
  'demo.myfootprint.app': TestModeService,

  'localhost:4200': SpartacusService,
  'myfootprint.bigcartel.com': MyfootprintBigCartelService,
  'tryon.footprinttech.de': LiveServiceForTryon,  // The test shop for tryon shop
};

function getService() {
  const domain = window.location.hostname;

  if (domain in ServiceMap) {
    return ServiceMap[domain];
  }

  return GenericService;
}

export class ShopServiceFactory {

  static create() {
    log.info('instantiating shop Service . . .');
    const Service = getService();
    return new Service();
  }

  static getShopService() {
    return getService();
  }

}
