import axios, { AxiosInstance } from 'axios';
import log from '../services/LogService';

interface ClientEvent {
  event_name: string,
  event_details?: string
}

class ClientEventsService {
  private client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: process.env.API_URL,
    });
  }

  setSessionId(sessionId: string) {
    this.client.defaults.headers.common.Authorization = `Bearer ${sessionId}`;
  }

  createEvent(eventName: string, eventDetails?:string) {

    const payload: ClientEvent = {
      event_name: eventName
    };

    if (eventDetails) {
      payload.event_details = eventDetails;
    }

    return this.client.post(`client-events`, payload)
      .catch(err => log.warn(
        `something wnet wrong trying to persist client event ${err.message}`
      ));
  }

  createEventBatch(events:Array<ClientEvent>) {
    return this.client.post(`client-events`, {events})
      .catch(err => log.warn(
        `something wnet wrong trying to persist client event ${err.message}`
      ));
  }
}

export default new ClientEventsService();
