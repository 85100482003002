/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  Matching,
} from '../interfaces/ProductService.interface';
import { ShopwareHelper } from '../tools/ShopwareHelper';
import { PodolinoHelper } from './PodolinoHelper';
import AbstractShop from './AbstractShop';
import axios from 'axios';
import log from '../services/LogService';

const KeyMap = {
  'live.footprinttech.de': 'e2b0dcca-0090-5ecc-a6b2-617e397ad8e4',
  'lidl.footprinttech.de': 'f37d2370-386d-11ef-b46a-e3124c8b1f40',
  'shopware6.podolino.de': '2aff6f7b-c730-572b-930b-9e8a20fe2382',
  'defense.footprinttech.de': 'f2380950-459f-11ef-b720-e9ae1b45235e',
  'aai.footprinttech.de': 'e7c3ef3b-d3bf-47ae-bd60-b8896e18530e',
};

const getApiKey = () => {
  const { hostname } = window.location;
  return KeyMap[hostname];
};

function getArticleSizesFromUI() {
  const result = [];
  const query = 'select.product-detail-configurator-select-input > option';
  const items = document.querySelectorAll(query);
  items.forEach((item: HTMLElement) => result.push(item.innerText.trim()));
  return result;
}

function getSizeIDMap() {
  const ID_MAP = {};
  // get the select element by its class name
  const selectElement = document.getElementsByClassName(
    'custom-select product-detail-configurator-select-input',
  )[0] as HTMLSelectElement;

  // loop through the option elements in the select element
  for (let i = 0; i < selectElement.options.length; i++) {
    // get the value of each option element
    const optionID = selectElement.options[i].innerHTML.trim();
    const optionValue = selectElement.options[i].value;

    ID_MAP[optionID] = optionValue;
  }
}

function getArticleNumber() {
  return document.querySelector('[itemprop=sku]').innerHTML.split('.')[0].trim();
}

function getArticleName() {
  return document.querySelector('[itemprop=name]').innerHTML.split('|')[0].trim();
}

function openBasketInventory() {
  const basketButton: HTMLButtonElement =
    document.querySelector('a.header-cart-btn');
  if (!basketButton) {
    log.error('button a[title="Warenkorb"] not found');
    return;
  }
  basketButton.click();
}

function addToCart(articleId: string) {
  const csrfToken = document
    .querySelector("#productDetailPageBuyProductForm input[name='_csrf_token']")
    .getAttribute('value');
  const form = new FormData();

  form.append('_csrf_token', csrfToken);
  form.append(`lineItems[${articleId}][quantity]`, '1');
  form.append('redirectTo', 'frontend.cart.offcanvas');
  form.append(`lineItems[${articleId}][id]`, articleId);
  form.append(`lineItems[${articleId}][type]`, 'product');
  form.append(`lineItems[${articleId}][referencedId]`, articleId);
  form.append(`lineItems[${articleId}][stackable]`, '1');
  form.append(`lineItems[${articleId}][removable]`, '1');
  form.append('product-name', 'Tanners Mid-Court Roscoe');
  form.append('brand-name', '');

  const options = { headers: {} };
  options.headers['X-Requested-With'] = 'XMLHttpRequest';

  const itemAddURL = window.location.origin.includes('elten.footprinttech.de')
    ? 'https://elten.footprinttech.de/checkout/line-item/add' // elten.footprinttech.de; this addToCart should never be called for Elten.
    : 'https://live.footprinttech.de/checkout/line-item/add'; // live.footprinttech.de

  return axios
    .post(itemAddURL, form, options)
    .then((response) => log.info('Add To cart response', response));
}

function addGlobalStyle() {
  const style = document.createElement('style');
  style.innerHTML = `
        :root .btn {
          border-radius: 1rem;
        }
        :root .cms-block {
          padding: 2px;
        }
      `;
  document.head.appendChild(style);
}

export class PodolinoLiveService extends AbstractShop {
  articleId: string;
  shopware: ShopwareHelper;
  helper: PodolinoHelper;

  constructor() {
    super();
    const artnum = getArticleNumber();
    this.apiKey = getApiKey();
    this.themeName = process.env.PODOLINO_THEME;
    this.helper = new PodolinoHelper();
    this.articleNumber = artnum;
    this.articleId = artnum;
    this.articleName = getArticleName();
    this.customGv = 'adult';
    this.sizes = getArticleSizesFromUI();
    let meta = document.head.querySelector('meta[property="og:image"]') as HTMLMetaElement;

    if (!meta) {
      meta = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
    }

    this.productSrc = meta && meta.content ? meta.content : '';
    const footerForm = document.querySelector('.footer-contact-form');

    if (footerForm) {
      footerForm.remove();
    }

    addGlobalStyle();
  }

  getArticleData() {

    if (document && !this.productSrc) {
      let meta = document.head.querySelector('meta[property="og:image"]') as HTMLMetaElement;

      if (!meta) {
        meta = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
      }

      this.productSrc = meta && meta.content ? meta.content : '';
      document.querySelector('.footer-contact-form').remove();
      addGlobalStyle();
    }

    return {
      id: this.articleId,
      number: this.articleNumber,
      name: this.articleName,
      image: this.productSrc,
      price: this.price,
      sizes: this.sizes,
    };
  }

  /* Interface implentation */

  handleMatching(matching: Matching) {
    log.info('handling matching');
    const SIZE_ID_MAP = getSizeIDMap();
    const variantId = SIZE_ID_MAP[matching.size.value];

    if (window.location.origin.includes('elten.footprinttech.de')) return; // do nothing, just exit

    return addToCart(variantId)
      .then(() => {
        openBasketInventory();
      })
      .catch((error) => {
        log.error(`something went wrong ${error.message}`);
      });
  }

  hasTooltip() {
    return true;
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': getApiKey(),
        'X-SHOP-DOMAIN': window.location.origin,
      },
    });
  }
}
