import axios, { AxiosInstance } from 'axios';
import log from '../services/LogService';

interface MatchData {
  measurement_id: string;
  session_id: string;
  article_number: string;
}

class MatchingService {
  private params: MatchData;

  private client: AxiosInstance;

  constructor() {

    this.params = {
      measurement_id: '',
      session_id: '',
      article_number: '',
    };

    this.client = axios.create({
      baseURL: process.env.API_URL,
    });

  }

  setSessionId(sessionId: string) {
    this.params.session_id = sessionId;
    this.client.defaults.headers.common.Authorization = `Bearer ${sessionId}`;
  }

  setMeasurementId(measurementId: string) {
    this.params.measurement_id = measurementId;
  }

  setArticleNumber(artNum: string) {
    this.params.article_number = artNum;
  }

  createMatching() {
    log.info('create Matching called', this.params);
    return this.client.post('/matchings', this.params);
  }
}

export default new MatchingService();
