import AbstractShop from './AbstractShop';
import { IVariant, Purchase } from '../interfaces/shopify.interface';
import axios from 'axios';
import {
  Matching,
} from '../interfaces/ProductService.interface';
import storageService from './StorageService';
import { ConversionsService } from './ConversionsService';
import log from '../services/LogService';

interface PurchaseData {
  purchase: Purchase;
  article: any;
  matching: any;
  service: any;
}

class ShopifyShop extends AbstractShop {

  variants: Array<IVariant>;

  handleMatching(matching: Matching) {

    log.info('handle matching Shopify');

    const variant = this.variants.find(
      item => item.size == matching.size.value && item.available);

    if (!variant) {
      alert('ERROR: Shoe Size not found');
      return Promise.reject();
    }

    storageService.addMatching(matching, this.articleId);
    storageService.update('measurementId', matching.measurement_id);

    const form = new FormData();

    form.append('Size', variant.size);
    form.append('form_type', 'product');
    form.append('utf8', '✓');
    // form.append('return_to', '/checkout');
    form.append('id', '' + variant.id);
    form.append('footprint_id', matching.matching_id);

    // const options = { headers: {} };
    // options.headers['X-CSRF-Token'] = csrfToken;

    return axios.post('/cart/add', form)
      .then(response => {
        log.info('Add To cart response', response);
        window.location.href = '/cart';
      });
  }

  getArticlePriceFromVariant(variant) {
    const { price } = variant;

    if (!price || Number.isNaN(price) || Number.isNaN(Number.parseInt(price))) {
      return null;
    }

    return price / 100;
  }

  static isCheckout() {
    return window.location.pathname.includes('checkouts');
  }

  static processPurchase(purchaseData: PurchaseData) {

    log.info('processing purchase', purchaseData);
    const { matching, purchase, article, service } = purchaseData;

    const data = {
      matching_id: matching.matching_id,
      price: Number(article.price),
      quantity: Number(article.quantity),
      tax: Number(purchase.tax),
      total: Number(purchase.value),
      currency: purchase.currency,
      client_session_id: '' + purchase.transaction_id,
    };

    log.info('posting conversion', data);

    return service.postConversion(data);
  }

  static isPurchase() {
    log.warn('Abstract isPurchase called');
    return false;
  }

  static getPurchase():Purchase {
    log.warn('Abstract getPurchase called');
    return null;
  }

  static getApiKey() {
    log.warn('Abstract getApiKey called');
    return '';
  }

  static findArticlePurchaseData(id:string, purchase:any) {
    log.info('Looking for purchase with id', id);
    return purchase.items.find(item => item.id == id);
  }

  static handleCheckout() {

    const isPurchase = this.isPurchase();

    if (!isPurchase) {
      const err = new Error('No data found for FPT purchase');
      return Promise.reject(err);
    }

    const fptSessionStr = sessionStorage.getItem('fpt-session');

    if (!fptSessionStr) {
      const err = new Error('No purchase data found');
      return Promise.reject(err);
    }

    const fptSession = JSON.parse(fptSessionStr);
    const articles = Object.getOwnPropertyNames(fptSession.matchings);

    if (articles.length == 0) {
      log.info('No matchings found', fptSession.matchings);
      return Promise.resolve(null);
    }

    log.info('searching for purchase');
    const purchase = this.getPurchase();

    if (!purchase) {
      const err = new Error('unable to identify purchase object');
      return Promise.reject(err);
    }

    log.info('found purchase', purchase);

    const conversionsService = new ConversionsService({
      apiKey: this.getApiKey(),
      baseUrl: process.env.API_URL,
    });

    const filteredArticles = articles.filter(
      articleId => this.findArticlePurchaseData(articleId, purchase));

    if (!filteredArticles.length) {
      log.info('no FPT matchings found');
    }

    const promises = filteredArticles.map(articleId => this.findArticlePurchaseData(articleId, purchase))
      .map(item => this.processPurchase({
        purchase,
        article: item,
        matching: fptSession.matchings['' + item.id],
        service: conversionsService,
      }));

    return Promise.all(promises);
  }

}

export default ShopifyShop;
