/* Helper class for PodolinoService) */
import log from '../services/LogService';

export class PodolinoHelper {

  parseArticleNumber(data: any): string {
    let artNumberPattern = /\d{12}$/;
    if (artNumberPattern.test(data.articleName)) {
      let nr = artNumberPattern.exec(data.articleName)[0];
      return nr.substring(0, 2) + ' ' + nr.substring(3, 8) + '/' + nr.substring(9, 12);
    }
    artNumberPattern = /\/(\d+)$/;

    const nodes = document.querySelectorAll('.entry--content');
    const articleNode = nodes[1];
    const hyperlinkNode = articleNode.querySelector('a');
    let articleNumber;
    const originalNumber = articleNode.innerHTML.trim();

    if (hyperlinkNode && artNumberPattern.test(originalNumber)) {
      const trailing = artNumberPattern.exec(originalNumber)[1];
      articleNumber = hyperlinkNode.innerHTML.trim() + '/' + trailing;
    } else if (hyperlinkNode) {
      articleNumber = hyperlinkNode.innerHTML.trim();
    } else {
      articleNumber = articleNode.innerHTML.trim();
    }

    return articleNumber;
  }

  getArticleSizesFromUI() {
    const result = [];
    const query = '.configurator--variant .variant--group .variant--option input';
    const items =  document.querySelectorAll(query);
    items.forEach(item => result.push(item.getAttribute('title')));
    return result;
  }

  isV3Shoe(el:HTMLElement, article:any) {

    if (!el) {
      return false;
    }

    const artNr = el.innerText.trim().split('.')[0];

    return (artNr === 'V3AppShoe') || (artNr === 'SW10027') || article.articleName.includes('V3');
  }

  isV2Shoe(el:HTMLElement, article:any) {

    if (!el) {
      return false;
    }

    const artNr = el.innerText.trim().split('.')[0];

    return (artNr === 'SW10016') || article.articleName.includes('V2');
  }

  hasCustomGV(el:HTMLElement) {
    if (!el) {
      return false;
    }

    const artNr = el.innerText.trim().split('.')[0];
    return (artNr === 'eltenTest') || (artNr === 'SW10027');
  }

  getCustomGV(el:HTMLElement, article:any) {
    let result = '2-step-child';
    const linkDetail = article.linkDetailsRewritten;
    log.info('[helper] linkDetail', linkDetail);

    if (linkDetail.includes('clothing/men')) {
      result = '3-step-blur-adult-v3.1';
    } else if (this.hasCustomGV(el)) {
      result = '3-step-blur-adult-v3.1';
    }

    return result;
  }

}
