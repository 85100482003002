import React from 'react';
import { ModalService } from '../components/Common/Modal';
import PromiseWindow from 'promise-window';
import {
  ArticleData,
  MeasurementParams,
  ShopSettings,
} from '../interfaces/ProductService.interface';
import { DefaultTheme } from '../theme';
import { DefaultError } from '../error/DefaultError';

const SUPPORTED_LANGUAGES = ['en', 'es', 'de', 'ja', 'fr', 'pl', 'tr'];

function isMobile() {
  return window.matchMedia('only screen and (max-width: 760px)').matches;
}

function isStagingRicosta() {
  const url = new URL(window.location.href);
  return url.hostname === 'staging.ricosta.de';
}

function isRicosta() {
  const url = new URL(window.location.href);
  return url.hostname.includes('ricosta.de');
}

function isSpartacus() {
  return window.location.hostname.includes('fashion-spa');
}

function applyRicostaBanner() {
  console.log('[driver] applying banner');
  const el = document.querySelector(`#footprinttech-button`) as HTMLElement;
  const shoesizePanel = el.previousElementSibling as HTMLElement;
  shoesizePanel.style.setProperty('background-color', '#ffda85');
  shoesizePanel.style.setProperty('padding', '0.5rem 1.5rem 0 1.5rem');
  shoesizePanel.style.setProperty('border-radius', '0.625rem 0.625rem 0 0');
  shoesizePanel.style.setProperty('margin-top', '2.25rem');
  shoesizePanel.style.setProperty('position', 'relative');
  const baseInfo = shoesizePanel.querySelector('.base-info--entry') as HTMLElement;
  baseInfo.style.setProperty('position', 'absolute');
  baseInfo.style.setProperty('top', '-1.5rem');
  baseInfo.style.setProperty('left', '0');
  const desc = shoesizePanel.querySelector('.configurator--description') as HTMLElement;
  desc.style.setProperty('color', '#00202a');
  desc.style.setProperty('font-size', '0.875rem');
  desc.style.setProperty('text-align', 'center');
  desc.innerHTML = 'Selbst die Größe wählen';
  const selectFields = shoesizePanel.querySelectorAll('.field--select');
  selectFields.forEach((item: HTMLElement) => item.style.setProperty('max-width', '100%'));

  el.style.setProperty('background-color', '#ffda85');
  el.style.setProperty('padding', '0 1.5rem 1rem');
  el.style.setProperty('border-radius', '0 0 0.625rem 0.625rem');
  el.style.setProperty('text-align', 'center');
  el.style.setProperty('color', '#00202a');
  el.style.setProperty('font-size', '0.875rem');
  el.style.setProperty('margin-bottom', '0.625rem');
  el.style.setProperty('overflow', 'hidden');
}

function getParentForm() {
  return document.getElementById('footprinttech-button').closest('form');
}

function preventFormSubmitOnEnterKeyPress() {
  const form = getParentForm();
  if (!form) return;
  form.addEventListener('keydown', (e) => {
    const key = e.key;
    if (key == 'Enter') {
      console.log('[driver] prevented enter sumbit');
      e.preventDefault();
    }
  });
}

function getButtonEl(): HTMLElement {
  return document.querySelector(`#footprinttech-button button`) as HTMLElement;
}

function getFPTTag(): HTMLElement {
  const buttonParent = document.getElementById('footprinttech-button');
  const banner = document.getElementById('footprinttech-banner');

  if (buttonParent) {
    return buttonParent as HTMLElement;
  }

  if (banner) {
    return banner as HTMLElement;
  }

  return null;

}

function parseTheme(customTheme) {
  const ctaTheme = Object.assign(DefaultTheme.ctaButton, customTheme.ctaButton);
  const ctaLinkTheme = Object.assign(DefaultTheme.ctaLink, customTheme.ctaLink);
  const theme = Object.assign(DefaultTheme, customTheme);
  theme.ctaButton = ctaTheme;
  theme.ctaLink = ctaLinkTheme;
  return theme;
}

function clearUndefinedData(data: any) {
  Object.getOwnPropertyNames(data).forEach(key => {
    if (data[key] === '' || data[key] === undefined) {
      delete data[key];
    }
  });
  return data;
}

function getFptUrl(target: string, data: MeasurementParams) {
  clearUndefinedData(data);
  const params = new URLSearchParams(data as any);
  return target + '?' + params.toString();
}

const RenderUtil = {
  isButtonRendered: () => {
    const el = getButtonEl();
    return !!(el && el.children.length > 1);
  },
  hasBannerVariant: () => {
    return isRicosta();
  },
  isSpartacus,
  isButtonVisible: () => {
    return true;
  },
  isMobile: isMobile,
  isDesktop: () => !isMobile(),
  isRicosta: isRicosta,
  isStagingRicosta: isStagingRicosta,
  applyBanner: () => {
    if (isMobile() || isRicosta()) {
      applyRicostaBanner();
    }
  },
  getFptUrl,
  openFptApp: (target: string, data: MeasurementParams): Promise<any> => {

    console.log('[driver] opening FPT App with params', data);
    console.log('[driver] opening target', target);

    // this as any: using only "this" shows an error in the IDE
    // stating that "this is possibly undefined"
    const url = getFptUrl(target, data);

    let promiseWindow = new PromiseWindow(url, {
      originRegexp: new RegExp(target),
    });

    return promiseWindow.open()
      .catch(err => {
        if (err === 'closed') {
          throw new DefaultError('WDCNV1', 'App closed by user');
        }

        throw new DefaultError(
          'GEN01-00',
          'Something went wrong with promise window',
          { cause: err });
      });
  },
  openModal: () => ModalService.open(),
  closeModal: () => {
    console.log('[driver] Closing modal');
    ModalService.close();
  },
  getLang: () => {

    if (navigator.languages && navigator.languages.length > 0) {
      const preferredLang = navigator.languages[0].toLowerCase().split('-')[0];
      const result = SUPPORTED_LANGUAGES.includes(preferredLang) ? preferredLang : 'en';
      return result;
    }

    return navigator.language;
  },
  parseTheme,
  getButtonEl: ():HTMLElement => {
    return getButtonEl();
  },
  getFPTTag: ():HTMLElement => {
    return getFPTTag();
  },
  setCtaButtonState: (state:string) => {
    const button = getButtonEl();
    if (!button) {
      return;
    }
    if (state == 'LOADING') {
      button.classList.add('fpt-loading');
    } else {
      button.classList.remove('fpt-loading');
    }
  },
  getTranslationMessages: (lang:string, i18nConfig:any) => {

    const messages = i18nConfig[lang].messages

    // check for missing keys
    if (lang !== 'en') {
      const masterKeys = Object.keys(i18nConfig['en'].messages)
      const currentKeys = Object.keys(messages)

      const difference = masterKeys.filter((x) => !currentKeys.includes(x))

      difference.forEach((item) => {
        messages[item] = i18nConfig['en'].messages[item]
      })
    }

    return messages;
  },
  hasFptBanner: () => {
    return !!document.getElementById('footprinttech-banner');
  },
  parseArticlePrice: (price:number, settings:ShopSettings) => {
    const { pricePattern } = settings;
    if (!pricePattern) {
      return price;
    }
    const pattern = new RegExp(pricePattern);
    const priceStr = '' + price;
    const parsedPrice = pattern.test(priceStr) ? priceStr.replace(pattern,
      (match, p1,p2) => `${p1}.${p2}`) : price;
    return Number(parsedPrice);
  },
  appendModalEl: () => {
    let modalEl =document.querySelector('#fpt-modal');

    if (modalEl) {
      console.warn('[driver] FPT modal already exists . . .');
    } else {
      modalEl = document.createElement('div');
      modalEl.setAttribute('id', 'fpt-modal');
      document.body.appendChild(modalEl);
    }

    return modalEl;
  },
  appendHeadlessModalEl: () => {
    let modalEl =document.querySelector('#fpt-modal');
    const modalRoot = document.querySelector('[data-widget=fpt-modal]');

    if (modalEl) {
      console.warn('[driver] FPT modal already exists . . .');
    } else {
      modalEl = document.createElement('div');
      modalEl.setAttribute('id', 'fpt-modal');
      modalRoot.appendChild(modalEl);
    }

    return modalEl;
  },
  getArticleDataFromModal: () => {
    const el = document.querySelector('[data-widget=fpt-modal]') as HTMLElement;
    let result:ArticleData = {
      id: '',
      number: '',
      sizes: []
    };

    if (!el) {
      return result;
    }

    if (el.dataset.articleNumber) {
        result.number = el.dataset.articleNumber;
    }

    if (el.dataset.articleName) {
        result.number = el.dataset.articleName;
    }

    if (el.dataset.articleImage) {
        result.number = el.dataset.articleNumber;
    }

    if (el.dataset.returnUrl) {
      result.returnUrl = el.dataset.returnUrl;
    }

    if (el.dataset.mode) {
      result.mode = el.dataset.mode;
    }

    return result;
  },
  getMeaFrame: () => {
    return document.getElementById("FPT-MEA-FRAME");
  }
}

export default RenderUtil;
