import ShopifyShop from './ShopifyShop';
import { Purchase } from '../interfaces/shopify.interface';
import axios from 'axios';
import log from '../services/LogService';

function getVariantSize(variant) {
  return variant.sku.split('-')[1];
}

export class MiretService extends ShopifyShop {

  constructor() {
    super();

    const productData = (window as any).meta?.product;
    const variant = productData.variants[0];

    this.articleName = variant.name.split('-')[0].trim();
    this.articleNumber = variant.sku.split('-')[0].trim();
    this.articleId = productData.id;
    this.price = this.getArticlePriceFromVariant(variant);

    this.variants = productData.variants.map((item) => {
      return {
        id: item.id,
        available: true,
        size: getVariantSize(item),
      };
    });

    let meta = document.head.querySelector('meta[property="og:image"]') as HTMLMetaElement;

    if (!meta) {
      meta = document.querySelector('meta[property="og:image"]') as HTMLMetaElement;
    }

    this.productSrc = meta && meta.content ? meta.content : '';
    // API key provided as data attribute this.apiKey = apiKey;
    this.themeName = 'default';
    this.sizes = productData.variants.map(item => getVariantSize(item));
  }

  async onCtaButtonClick(): Promise<any> {
    const box = document.querySelector('.site-box.product-gallery') as HTMLElement;
    log.info('looking for sticky box', box);
    if (box) {
      log.info('applying fix for sticky box');
      // box.style.position = 'fixed';
    }
    return super.onCtaButtonClick();
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': MiretService.getApiKey(),
      },
    });
  }

  static isPurchase() {
    const shopInterface = (window as any).Shopify;
    return (
      window.location.pathname.includes('checkouts') ||
      window.location.pathname.includes('orders')
    ) &&
      shopInterface && shopInterface.checkout &&
      shopInterface.checkout.line_items &&
      shopInterface.checkout.line_items.length > 0;
  }

  static getPurchase(): Purchase {
    const { checkout } = (window as any).Shopify;

    return {
      tax: checkout.total_tax,
      value: checkout.total_price,
      shipping: checkout.shipping_rate.price,
      currency: checkout.currency,
      transaction_id: checkout.order_id,
      items: checkout.line_items.map(item => {
        return {
          id: item.product_id,
          price: item.price,
          quantity: item.quantity,
        };
      }),
    };
  }

  static getApiKey() {
    return '5808049e-70bc-584b-939e-746b731b5bed';
  }

}
