import React, { FunctionComponent } from 'react';

export const MeasurementComponent: FunctionComponent<any> = (props) => {

  const { src } = props;

  return (
    <>
    <style>
    {
      `
      html {
        overflow: auto;
      }

      html, body, div, iframe {
        margin: 0px;
        padding: 0px;
        height: 100%;
        border: none;
      }

      iframe {
       display: block;
       width: 100%;
       border: none;
       overflow-y: auto;
       overflow-x: hidden;
      }
      `
    }
    </style>
    <iframe id="FPT-MEA-FRAME"
            name="MeasurementFrame"
            src={src}
            width="100%"
            height="100%"
            scrolling="auto"
            allow="fullscreen;camera;accelerometer;gyroscope;autoplay;"></iframe>
    </>
  );
}
