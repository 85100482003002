export enum LogMode {
  SILENT = 'SILENT',
  VERBOSE = 'VERBOSE',
  DEFAULT = 'DEFAULT',
}

class LogService {
  mode:LogMode;

  constructor() {
    const params = new URLSearchParams(window.location.search);
    const consoleParam = params.get('fpt-console') as LogMode;
    this.mode = consoleParam || LogMode.DEFAULT;
  }

  setMode(consoleMode:LogMode) {
    this.mode = consoleMode;
  }

  info(message:string, obj?:any) {
    if (this.mode !== LogMode.VERBOSE) return;
    if (obj) {
      return console.log(`[fpt] ${message}`, obj);
    }
    console.log(`[fpt] ${message}`);
  }

  warn(message:string) {
    if(this.mode === LogMode.SILENT) return;
    console.warn(`[fpt] ${message}`);
  }

  error(message:string) {
    if(this.mode === LogMode.SILENT) return;
    console.error(`[fpt] ${message}`);
  }
}

export default new LogService();
