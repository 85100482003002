import React from 'react';
import { createRoot } from 'react-dom/client';
import { AppController } from './AppController';
import { ButtonController } from './ButtonController';
import { ShopServiceFactory } from '../tools/ShopServiceFactory';
import EventEmitter from 'eventemitter3';
import shopService from '../services/ShopService';
import storageService from '../services/StorageService';
import { GenericService } from '../services';
import RenderUtil from '../util/RenderUtil';
import log from '../services/LogService';

export const emitter = new EventEmitter();

export const FootprintTechDriver = {
  render: (config) => {

    log.info('rendering widgets . . .');

    const customShopService = ShopServiceFactory.create();

    if (customShopService instanceof GenericService) {
      log.info('using Generic Service');
    } else {
      log.info('using Custom Service');
      shopService.setCustomService(customShopService);
    }

    const articleList = [];

    let apiKey = shopService.getApiKey(config);

    // set API key from custom service (deprecated)
    // or script element
    if (apiKey) {
      shopService.setApiKey(apiKey);
    }

    const renderButtonFromHTMLElement = (el:HTMLElement) => {
      const article = shopService.getArticleData(el);
      articleList.push(article);
      const root = createRoot(el);

      // set API key from widget. This is deprecated
      if (!apiKey) {
        apiKey = shopService.getApiKey(el.dataset);
        shopService.setApiKey(apiKey);
      }

      if ('measurementId' in el.dataset && el.dataset.measurementId) {
        const { measurementId } = el.dataset;
        log.info('got measurement', measurementId);
        storageService.setMeasurementId(measurementId);
      }

      root.render(
        <ButtonController emitter={emitter} articleData={article} />
      );
    };

    document.querySelectorAll('[data-widget=fpt-button]')
      .forEach(renderButtonFromHTMLElement);

    // this is deprecated. Does not work with data-widget attribute
    const legacyWidget = document.querySelector('#footprinttech-button');
    if (legacyWidget) {
      const article = shopService.getArticleData(legacyWidget as HTMLElement);
      articleList.push(article);
      renderButtonFromHTMLElement(legacyWidget as HTMLElement);
    }

    if (!apiKey) {
      throw new Error('No API key found');
    }

    const modalEl = RenderUtil.appendModalEl();

    const appRoot = createRoot(modalEl);

    appRoot.render(
          <AppController emitter={emitter} articleList={articleList} />
    );
  },

  renderModal: () => {
    log.info('Headless mode initilizing');
    const el = RenderUtil.appendHeadlessModalEl();
    const root = createRoot(el);

    root.render(
        <AppController emitter={emitter} headlessEnabled="true" />
    )
  },

  isCheckoutPage: () => {
    const Service = ShopServiceFactory.getShopService();
    return Service.isCheckout();
  },

  initBanner: (bannerId:string) => {
    log.info('has banner');
    // const el = document.getElementById(bannerId);
    const el = RenderUtil.appendModalEl();
    const root = createRoot(el);

    root.render(
        <AppController emitter={emitter} bannerId="footprinttech-banner" />
    )
  },

  handleCheckout: () => {
    log.info('checkout called');
    const Service = ShopServiceFactory.getShopService();
    return Service.handleCheckout();
  }
}
