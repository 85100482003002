import React, { useState, useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { IntlProvider } from 'react-intl';
import { i18nConfig } from '../assets';
import { defaultShopSettings } from './AppState';
import storageService from '../services/StorageService';
import RenderUtil from '../util/RenderUtil';
import DefaultTheme from '../theme/DefaultTheme';
import {
  ArticleData,
  CurrencyISO4217,
  CONVERSION_ACTION,
  Matching,
  ShopSettings,
  Recommendation
} from '../interfaces/ProductService.interface';
import shopService from '../services/ShopService';
import checkoutService from '../services/CheckoutService';
import { DefaultError } from '../error/DefaultError';
import { CtaPanel } from '../components/CtaPanel';
import WidgetEvents from './Events';
import { ShopWidgetService } from '../services/ShopWidgetService';
import { Events } from '@footprint/common-components';
import { AssetsService } from '../services/AssetsService';
import EventEmitter from 'eventemitter3';
import SplashscreenEvents from '../components/Splashscreen/Events';

interface ButtonControllerProps {
  articleData: ArticleData;
  emitter: EventEmitter
}

export const ButtonController = (props: ButtonControllerProps) => {
  const { articleData, emitter } = props;
  console.log('[driver] initializing widget . . .');

  const widgetService = new ShopWidgetService(articleData);
  const initLang = RenderUtil.getLang();
  const initMessages = RenderUtil.getTranslationMessages(initLang, i18nConfig);

  const [ shopSettings, setShopSettings] = useState(defaultShopSettings as ShopSettings);
  const [ locale, setLocale ] = useState(i18nConfig[initLang].locale);
  const [ messages, setMessages ] = useState(initMessages);
  const [ customTheme, setCustomTheme ] = useState(DefaultTheme);
  const [ language, setLanguage ] = useState(initLang);
  let currentShopSettings = defaultShopSettings;

  const [ currentMatch, setCurrentMatch ] = useState(null as Matching);

  const handleMeasureAgainPress = () => {
    setCurrentMatch(null);
  };

  const handleLanguageSelect = (selectedLanguage: string) => {
    console.log(`[driver] Handling Language ${selectedLanguage} selected`);
    setLanguage(selectedLanguage);

    if (shopSettings.lang !== selectedLanguage) {
      shopSettings.lang = selectedLanguage;
      // setShopSettings(shopSettings);
      console.log(`[driver] shop settings in Button Controller`, shopSettings);
    }

    AssetsService.fetchLanguageFile(selectedLanguage)
      .then( result => {
        console.log('[driver] setting language', selectedLanguage);
        i18nConfig[selectedLanguage].messages = result;
        setLocale(i18nConfig[selectedLanguage].locale);
        const m = RenderUtil.getTranslationMessages(selectedLanguage, i18nConfig);
        setMessages(m);
      });
  };

  const processExistingMeasurement = () => {
    const measurementId = storageService.getMeasurementId();
    const hasExistingMeasurement = !!measurementId;

    if (!hasExistingMeasurement) {
      return;
    }

    // Profile measurement scenario
    let outcome = Promise.resolve();

    console.log('[driver] Found measurement, looking for a match', measurementId);

    if (articleData.gtin && !articleData.number) {
      console.log(`[driver] Article number missing, looking for article number for gtin ${articleData.gtin}`);
      outcome = widgetService.findArticleByGtin()
        .then(result => {
          console.log('[driver] got article from gtin', result);
          articleData.number = result.article_number;
          widgetService.setArticleNumber(result.article_number);
      });
    } else if (articleData.sku && !articleData.number) {
      console.log(`[driver] Article number missing, looking for article number for sku ${articleData.sku}`);
      outcome = widgetService.findArticleBySku()
        .then(result => {
          console.log('[driver] got article from sku', result);
          articleData.number = result.article_number;
          widgetService.setArticleNumber(result.article_number);
      });
    }

    outcome
    .then(() => shopService.handleExistingMeasurement(measurementId, articleData))
    .then((result:Matching) => {
      console.log('[driver] setting current match', result);
      setCurrentMatch(result);
      if (!result.isStoredInSession) {
        shopService.createConversion(
          result,
          articleData,
          CONVERSION_ACTION.MATCHING_SECONDARY,
          currentShopSettings
        );
        (result as Recommendation).session_id = storageService.getSessionId();
        const event = new CustomEvent('fpt-secondary-matching',
          { detail: result as any });
        window.dispatchEvent(event);
      }
    })
    .catch(err => {
      if (err instanceof DefaultError && (err.errorCode === 'MADB1-00' || err.errorCode == 'MADB2-00' || err.errorCode == 'BEDB1-00')) {
        setCurrentMatch({
          article_number: articleData.number,
          error_code: err.errorCode,
          measurement_id: measurementId,
          size: null,
        });
      }
      console.error(
        '[driver] something went wrong while processing measurement', err);
    });
  }

  useEffect(() => {
    emitter.addListener(WidgetEvents.onSettingsLoaded, data => {
      console.log('[driver] setting shop settings in widget', data);
      setShopSettings(data);
      currentShopSettings = data;
      processExistingMeasurement();
    });
    emitter.addListener(Events.onMeasureAgainPress, handleMeasureAgainPress);
    emitter.addListener(SplashscreenEvents.onLanguageSelect, handleLanguageSelect);
    emitter.addListener(
      WidgetEvents.onMatching,
      data => {

        if (currentShopSettings.multiRecommendationsEnabled) {
          return;
        }

        widgetService.handleMatching(data, currentShopSettings)
        .then(result => {
          console.log('[driver] Setting current match in widget', result);
          setCurrentMatch(result);
        })
    });

  }, []);

  useEffect(() => {

    console.log('[driver] Got shop settings:', shopSettings);

    if (!shopSettings.domain) {
      return;
    }

    const { lang, theme, pricePattern, currency } = shopSettings;

    if (lang && lang in i18nConfig) {
      setLanguage(lang);
      AssetsService.fetchLanguageFile(lang)
        .then( result => {
          console.log('[driver] setting language', lang);
          i18nConfig[lang].messages = result;
          setLocale(i18nConfig[lang].locale);
          const m = RenderUtil.getTranslationMessages(lang, i18nConfig);
          setMessages(m);
        });
    } else if (lang) {
      console.warn(`[driver] unsupported shop language setting: ${lang}`);
      AssetsService.fetchLanguageFile(language)
        .then( result => {
          console.log('[driver] setting language', language);
          i18nConfig[language].messages = result;
          setLocale(i18nConfig[language].locale);
          const m = RenderUtil.getTranslationMessages(language, i18nConfig);
          setMessages(m);
        });
    } else {
      console.warn(`[driver] setting browser language: ${language}`);
      AssetsService.fetchLanguageFile(language)
        .then( result => {
          console.log('[driver] setting language', language);
          i18nConfig[language].messages = result;
          setLocale(i18nConfig[language].locale);
          const m = RenderUtil.getTranslationMessages(language, i18nConfig);
          setMessages(m);
        });
    }

    console.log(`[driver] using article price pattern ${pricePattern}`);

    const priceNum = RenderUtil.parseArticlePrice(
      articleData.price, shopSettings);

    if (!articleData.currency) {
      articleData.currency = currency || CurrencyISO4217.EUR;
    }

    const selectedLanguage = lang || language;

    articleData.sum = checkoutService.formatCurrency(
      priceNum, selectedLanguage, articleData.currency);

    if (theme) {
      const parsedTheme = RenderUtil.parseTheme(theme);
      setCustomTheme(parsedTheme);
    }

  }, [shopSettings, language]);


  return (
    <>
    { (shopSettings.domain) &&
      (<IntlProvider
        locale={locale}
        defaultLocale={i18nConfig['en'].locale}
        messages={messages}>
            <ThemeProvider theme={customTheme}>
              <CtaPanel
                emitter={emitter}
                matching={currentMatch}
                settings={shopSettings}
                article={articleData}
              />
            </ThemeProvider>
      </IntlProvider>)
    }
    </>);
};
