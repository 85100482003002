import {
  Matching,
  ProductServiceInterface,
  CONVERSION_ACTION,
} from '../interfaces/ProductService.interface';
import RenderUtil from '../util/RenderUtil';
import articleService from './ArticleService';
import userService from './UserService';
import sessionService from '../services/SessionService';
import storageService from '../services/StorageService';
import conversionsService from './ConversionsService';
import EventEmitter from 'eventemitter3';
import log from '../services/LogService';

const emitter = new EventEmitter();

function hasArticle(data) {
  return data && data.length > 0;
}

function getSessionLastMeasurement() {
  return sessionService.getSessionMeasurements()
    .then(result => {
      if (result && result.length > 0) {
        return result[0];
      }
      return null;
    });
}

// Deprecated, use App Controller and Shop Service instead
abstract class AbstractShop implements ProductServiceInterface {
  articleName: string;

  articleNumber: string = '';

  articleId: string;

  apiKey: string;

  themeName: string = 'default';

  clientSessionId: string;

  sizes: Array<string> = [];

  productSrc: string = '';

  customGv: string = '2-step-child';

  price: number;

  currency = 'EUR';

  lang: string;

  hasPolling: boolean = false;

  steps: string = 'default';

  constructor() {
    const fptEl = RenderUtil.getFPTTag();
    this.lang = RenderUtil.getLang();

    if (!fptEl) {
      return;
    }

    if ('sessionId' in fptEl.dataset) {
      log.info('got client session id', fptEl.dataset.sessionId);
      this.clientSessionId = fptEl.dataset.sessionId;
    }

    if ('apiKey' in fptEl.dataset) {
      log.info('got API key', fptEl.dataset.apiKey);
      this.apiKey = fptEl.dataset.apiKey;
    }

    if ('articleNumber' in fptEl.dataset) {
      log.info('got article number', fptEl.dataset.articleNumber);
      this.articleNumber = fptEl.dataset.articleNumber;
    }

    if ('articleName' in fptEl.dataset) {
      log.info('got article name', fptEl.dataset.articleName);
      this.articleName = fptEl.dataset.articleName;
    }

    if ('articleImage' in fptEl.dataset) {
      log.info('got article image', fptEl.dataset.articleImage);
      this.productSrc = fptEl.dataset.articleImage;
    }

    if ('articlePrice' in fptEl.dataset) {
      log.info('got article price', fptEl.dataset.articlePrice);
      const str = fptEl.dataset.articlePrice;
      const price = Number(str);
      this.price = Number.isNaN(price) || str === '' ? null : price;
    }

    if ('currency' in fptEl.dataset) {
      log.info('got currency', fptEl.dataset.currency);
      this.currency = fptEl.dataset.currency;
    }
  }

  getApiKey() {
    return this.apiKey;
  }

  getTarget() {
    return process.env.FPT_APP_URL;
  }

  getArticleData() {
    return {
      id: this.articleId,
      number: this.articleNumber,
      name: this.articleName,
      image: this.productSrc,
      price: this.price,
      sizes: this.sizes,
    };
  }

  handleMatching(m: Matching) {
    log.warn('Add to cart called but feature is not supported');
    return Promise.resolve();
  }

  addShopListeners() {
    log.info('Abstract add shop listeners called');
  }

  removeShopListeners() {
    log.info('Abstract remove shop listeners called');
  }

  createSession(): Promise<any> {
    const anonymousId = storageService.getAnonymousId();

    const sessionData = {
      screen_height: window.innerHeight,
      screen_width: window.innerWidth,
      analytics_accepted: true,
      client_session_id: this.clientSessionId,
      anonymous_id: anonymousId,
      referrer: window.location.href,
    };

    return sessionService.createSession(sessionData);
  }

  updateSessionId(sessionId: string) {
    storageService.update('sessionId', sessionId);
    articleService.setSessionId(sessionId);
    sessionService.setSessionId(sessionId);
    userService.setSessionId(sessionId);
    return sessionId;
  }

  async checkIfArticleExists() {
    log.info('checking if article exists');
    const articleData = {
      articleNumber: this.articleNumber,
      articleName: this.articleName
    };

    return articleService
      .lookupArticle(articleData)
      .then((result) => {
        log.info('lookup article response', result);
        RenderUtil.setCtaButtonState('DONE');
        return hasArticle(result.data);
      })
      .catch((err) => {
        if (err.response && err.response.status == '401') {
          log.warn(`soemething went wrong with article lookup ${err.response.statusText}`);
          log.warn('creating new session');
          // Session has expired, create a new One
          return this.createSession()
            .then(result => this.updateSessionId(result))
            .then(() => articleService.lookupArticle(articleData))
            .then((result) => {
              log.info('lookup article response', result);
              RenderUtil.setCtaButtonState('DONE');
              return hasArticle(result.data);
            })
            .catch(() => {
              log.info('create new session or article lookup failed');
              RenderUtil.setCtaButtonState('DONE');
            });
        }

        const { message } = err;

        if (message) {
          log.warn(`soemething went wrong with article lookup ${message}`);
        }

        return false;
      });
  }

  getArticleId() {
    return this.articleId;
  }

  getArticleNumber() {
    return this.articleNumber;
  }

  getClientSessionId() {
    return this.clientSessionId;
  }

  hasReload() {
    return true;
  }

  hasTooltip() {
    return false;
  }

  createConversion(matching: Matching, action: CONVERSION_ACTION) {

    if (matching.error_code !== 'MAST0-00') {
      return;
    }

    const data: any = {
      matching_id: matching.matching_id,
      price: this.price,
      quantity: 1,
      tax: 0,
      total: this.price,
      currency: this.currency,
      action,
    };

    if (this.clientSessionId) {
      data.client_session_id = this.clientSessionId;
    }

    conversionsService.postConversion(data);
  }

  readClientCookie(key) {
    let cookie = document.cookie.split(';').find((item) => item.includes(key));
    if (cookie?.indexOf('=') > -1) {
      return cookie.split('=')[1];
    }
    return null;
  }

  static isCheckout() {
    return false;
  }

  static handleCheckout() {
    return Promise.resolve();
  }
}

export default AbstractShop;
