/* eslint-disable @typescript-eslint/lines-between-class-members */
import {
  Matching,
} from '../interfaces/ProductService.interface';
import { KoyobearHelper } from './KoyobearHelper';
import axios from 'axios';
import AbstractShop from './AbstractShop';
import log from '../services/LogService';

export class KoyobearService extends AbstractShop {
  helper: KoyobearHelper;

  constructor() {
    super();
    this.helper = new KoyobearHelper();
    if (!this.apiKey) {
      log.info('looking for API key');
      this.apiKey = KoyobearHelper.getApiKey();
    }
    this.themeName = 'Koyobear';
    this.articleNumber = 'koyobear-article';
    this.articleName = 'Sharpman';

    if (!this.productSrc) {
      this.productSrc =
        'https://uploads-ssl.webflow.com/61fa3df7d4d22423aa145f14/61fa6ebd6b79d8bf6fe3532f_koyobear-sharpman-detail-1.jpg';
    }
    this.sizes = this.helper.getArticleSizeList();
    this.customGv = '3-step-blur-adult-v3.1';
  }

  handleMatching(matching: Matching) {
    log.info('handling matching Koyobear');

    return Promise.resolve().then(() => {
      this.helper
        .selectSize(matching.size.value)
        .then((result: HTMLElement) => {
          this.helper.addToCart(result);
        })
        .catch((err: Error) => {
          console.error(err.message);
        });
    });
  }

  hasReload() {
    return true;
  }

  static createClient() {
    return axios.create({
      baseURL: process.env.API_URL,
      headers: {
        'X-API-KEY': KoyobearHelper.getApiKey(),
      },
    });
  }

  static getApiKey() {
    return KoyobearHelper.getApiKey();
  }
}
