import React, { FunctionComponent } from 'react';

export const MeasurementComponent: FunctionComponent<any> = (props) => {

  const { src } = props;
  const host = window.location.host;
  const fptAppUrl = new URL(src);
  const params = fptAppUrl.search;
  const measurementUrl = `https://${host}/measurement/${params}`;

  return (
    <>
    <style>
    {
      `
      html {
        overflow: auto;
      }

      html, body, div, iframe {
        margin: 0px;
        padding: 0px;
        height: 100%;
        border: none;
      }

      iframe {
       display: block;
       width: 100%;
       border: none;
       overflow-y: auto;
       overflow-x: hidden;
      }
      `
    }
    </style>
    <iframe id="FPT-MEA-FRAME"
            name="MeasurementFrame"
            src={measurementUrl}
            width="100%"
            height="100%"
            scrolling="auto"
            allow="fullscreen;camera;accelerometer;gyroscope;autoplay;"></iframe>
    </>
  );
}
