import React, {
  FunctionComponent,
  useState,
  useEffect,
} from 'react';
import styled from 'styled-components';
import { Tooltip } from '../Tooltip';
import RenderUtil from '../../util/RenderUtil';
import storageService from '../../services/StorageService';
import { TOOLTIP_ENABLED } from '../../interfaces/ProductService.interface';
import log from '../../services/LogService';

const Link = styled.a`
  color: #81bcbb;
  text-decoration: underline;
`;

const Hint = styled.div`
  font-family: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande',
    sans-serif;
  font-size: 0.875rem;
  line-height: 20px;
  color: #5f7285;
  opacity: 1;
  margin-top: 10px;
  text-align: center;
`;

const TOOLTIP_MAP = {
  INITIAL: 'drv.cta-button.tooltip.text',
  RECOMMENDATION: 'drv.matching-result.tooltip.text'
};

export const TooltipContainer: FunctionComponent<any> = (props) => {

  const { children, messageId, settings } = props;
  const isTooltipVisible = () => {
    const setting = settings.tooltipEnabled;

    if (!setting || setting === TOOLTIP_ENABLED.NONE) {
      return false;
    }

    if (setting === TOOLTIP_ENABLED.INITIAL &&
        messageId !== TOOLTIP_MAP.INITIAL) {
      return false;
    }

    if (setting === TOOLTIP_ENABLED.RECOMMENDATION &&
        messageId !== TOOLTIP_MAP.RECOMMENDATION) {
      return false;
    }

    return !storageService.isTooltipViewed(messageId);
  };

  const [tooltipVisible, setTootipVisible] = useState(isTooltipVisible());
  log.info(`tooltip visible ${tooltipVisible}`);

  const closeTooltip = () => {
    log.info('close called');
    setTootipVisible(false);
    storageService.addViewedTooltip(messageId);
  }

  const refreshTooltip = () => {
    setTootipVisible(isTooltipVisible());
  }

  const buttonId = 'footprinttech-button';

  let options = {
    root: null,
    rootMargin: "0px",
    threshold: 1.0,
  };

  let observer = new IntersectionObserver((entries) => {
    log.info('observer callback called');
    entries.forEach(item => {
      if (!isTooltipVisible()) return;
      setTootipVisible(item.isIntersecting);
    });
  }, options);

  const btn = document.querySelector(`#${buttonId}`);

  if (btn) {
    observer.observe(btn);
  }

  if (!RenderUtil.isButtonVisible()) {
    log.info('device is not mobile, hiding')
    return <></>
  }

  useEffect(() => {
    window.addEventListener('fpt-reload', refreshTooltip);

    return () => {
      window.removeEventListener('fpt-reload', refreshTooltip);
    }
  }, [])

  // I am not sure why  FormattedMessage is not used here before, i hope this wont cause an issue here. But For multi-lang support I needed.
  return (
    <Tooltip
      onCloseClick={closeTooltip}
      offset={30}
      visible={tooltipVisible}
      containerStyle={{ marginBottom: '0' }}
      messageId={messageId}
    >
      {children}
    </Tooltip>
  )
}
