import { FootprintTechDriver } from './main/FootprintTech/FootprintTechDriver';
import log from './main/services/LogService';

(function (Driver) {
  log.info('initializing . . .');
  const scriptEl = document.currentScript as HTMLElement;

  const config = {
    apiKey: scriptEl.dataset.apiKey,
  };

  let isButtonRendered = false;

  window.FootprintTechDriver = Driver;

  document.addEventListener('readystatechange', (event) => {

    const target: Document | any = event.target;
    const isCheckout = Driver.isCheckoutPage();
    const hasButton = !!document.querySelector('[data-widget=fpt-button]') ||
      !!document.querySelector('#footprinttech-button');
    const hasBanner = !!document.querySelector('[data-widget=fpt-banner]') ||
     !!document.querySelector('#footprinttech-banner');
     const headlessEnabled = !!document.querySelector('[data-widget=fpt-modal]');

    log.info(`ready state changed ${target.readyState}`);

    if (target.readyState === 'interactive' &&
      !isCheckout &&
      hasButton &&
      !isButtonRendered &&
      !headlessEnabled
    ) {
      log.info('button rendered interactive');
      Driver.render(config);
      isButtonRendered = true;
    }

    if (target.readyState === 'interactive' &&
      !isCheckout &&
      hasBanner &&
      !headlessEnabled) {
      log.info('banner initialized interactive');
      Driver.initBanner('footprinttech-banner');
    }

    if (target.readyState === 'complete' &&
      hasButton &&
      !isCheckout &&
      !isButtonRendered &&
      !headlessEnabled) {
      log.info('ready state complete, rendering the button');
      Driver.render(config);
      isButtonRendered = true;
    }

    if (target.readyState === 'complete' &&
      headlessEnabled) {
      log.info('ready state complete, rendering modal');
      Driver.renderModal();
    }

    if (target.readyState !== 'complete') return;

    log.info('ready state complete');

    if (isCheckout) {
      return window.addEventListener('load', () => {
        log.info('page is fully loaded, calling checkout');
        Driver.handleCheckout()
          .catch(err => log.warn(`no conversion created ${err}`));
      });
    }

  });

  log.info(`document ready state: ${document.readyState}`);

  if (document.readyState === 'complete' &&
    document.querySelector('#footprinttech-button') &&
    !isButtonRendered) {
    log.info('page is fully loaded, but button not rendered');
    log.info('renderedering button');
    Driver.render(config);
    isButtonRendered = true;
  } else if (document.readyState === 'complete' && !isButtonRendered) {
    log.warn('page is fully loaded, but button not rendered');
    log.warn('unable to find button div element');
    log.warn('waiting for button div element to render');
    setTimeout(() => {
      log.warn('trying to render button');
      Driver.render(config);
      isButtonRendered = true;
    }, 1000);
  }

})(FootprintTechDriver);
