import {
  PodolinoService,
  TestModeService,
  KoyobearService,
  SpartacusService,
  swtestenvironmentService,
  PodolinoLiveService,
  LiveServiceForEltenTesting,
  LiveServiceForTryon,
  MyfootprintBigCartelService,
  MiretService,
  BillyFootwearService,
  VicinityService,
  GenericService,
} from '../services';

export const ServiceMap = {
  'www.koyobear.com': KoyobearService,
  'koyo-bear-test-4-2.webflow.io': KoyobearService,
  'koyo-bear-footprint-test.webflow.io': KoyobearService,
  'www.vicinityclo.de': VicinityService,
  'billyfootwear.com': BillyFootwearService,
  'sandbox-billy-footwear.myshopify.com': BillyFootwearService,
  'www.miret.co': MiretService,
  'www.earthbound-sneakers.com': MiretService,
  'sonra.bigcartel.com': GenericService,
  'www.sonra.de': GenericService,
  'jp.koyobear.com': KoyobearService,
  'elten.footprinttech.de': LiveServiceForEltenTesting,  // The test shop for Elten's PCH Testing on 28.03.2023
  'fashion-spa.cu2qdtboy0-public1-p13-public.model-t.cc.commerce.ondemand.com': SpartacusService,
  'fashion-spa.sfashion-sapphire.usdemo.hybris.com': SpartacusService,
  'footprintapp-egsvi.sw-testenvironment.de': swtestenvironmentService,

  'shopware.podolino.de': PodolinoService,
  'footprinttech-dev-store.myshopify.com': GenericService,
  'staging.podolino.de': PodolinoService,
  'live.footprinttech.de': GenericService,
  'orange-ocean-017423203.1.azurestaticapps.net': TestModeService,
  'demo.myfootprint.app': TestModeService,

  'localhost': GenericService,
  'localhost:4200': SpartacusService,
  'myfootprint.bigcartel.com': MyfootprintBigCartelService,
  'tryon.footprinttech.de': LiveServiceForTryon,  // The test shop for tryon shop
  'www.josef-seibel.de': GenericService,
  'jsapps.c6yfzocx5l-bwbekleid1-p2-public.model-t.cc.commerce.ondemand.com': GenericService,
  'stg-webshop.bwbm.de': GenericService,
  'shop.bwbm.de': GenericService,
  'lidl.footprinttech.de': GenericService,
  'defense.footprinttech.de': GenericService,
  'shopware6.podolino.de': GenericService,
  'www.kjavik.com': GenericService,
  'aai.footprinttech.de': GenericService,
  'bwlp.footprinttech.de': GenericService,
  'bwlp-staging.footprinttech.de': GenericService,
  'teamkicks.footprinttech.de': GenericService,
  'cdn.footprinttech.de': GenericService,
  'meindlusa.com': GenericService,
};

function getService() {
  const domain = window.location.hostname;
  return ServiceMap[domain];
}

export class ShopServiceFactory {

  static create() {
    console.log('[driver] instantiating shop Service . . .');
    const Service = getService();
    return new Service();
  }

  static getShopService() {
    return getService();
  }

}
