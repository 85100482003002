import { Matching, ProductServiceInterface } from '../interfaces/ProductService.interface';
import { TestSettings } from '../types/TestSettings';
import log from '../services/LogService';

export class TestModeService implements ProductServiceInterface {
  testSetting: TestSettings;

  constructor() {
    this.testSetting = global.testSettings;
  }

  getTarget() {
    switch (this.testSetting.env) {
      default:
      case 'dev':       return 'https://dev-app.myfootprint.app';
      case 'test':      return 'https://test-app.myfootprint.app';
      case 'staging':   return 'https://staging-app.myfootprint.app';
      case 'prod':      return 'https://app.myfootprint.app';
    }
  }

  getProductData() {
    return {
      articleName: this.testSetting.articleName,
      articleNumber: this.testSetting.articleId,
      apiKey: this.testSetting.apiKey,
      theme: this.testSetting.theme || 'default',
      sizes: this.testSetting.sizes,
      variant: Math.random() > 0.5 ? 1 : 0,
      mode: this.testSetting.testMode ? 'test' : null,
      'custom-gv': this.testSetting.adultMode ? '3-step-blur-adult-v3.1' : null,
    };
  }

  handleMatching(matching: Matching) {
    log.info(`handling matching for ${matching.size}`);
    log.error('handling matching not implemented in test mode');
    return null;
  }

}
