import { createRoot } from 'react-dom/client';
import log from '../../../services/LogService';

let instance;

function getModal() {
  return (
    (document.querySelector('.fpt-modal') as HTMLElement) ||
    (() => {
      throw new Error('modal dom element not found');
    })()
  );
}

function getModalContentWrapper() {
  return (
    document.querySelector('.fpt-modal__content') ||
    (() => {
      throw new Error('modal dom element not found');
    })()
  );
}

function getRootInstance() {
  if (!instance) {
    const wrapper = getModalContentWrapper();
    instance = createRoot(wrapper);
  }

  return instance;
}

export const ModalService = {
  open: () => {
    getModal().style.display = 'flex';
  },
  close: () => {
    getModal().style.display = 'none';
  },
  setContent: (content: any) => {
    log.info('setting content');
    const root = getRootInstance();
    root.render(content);
  },
  isOpen: () => {
    return getModal().style.display === 'flex';
  },
};
